export const environment = {
  production: false,
  envName: 'stage',
  PERMISSION_DELETE: ['Bernd Krech', 'Chiara Riley'],
  HIDE_CUSTOM_FILTER: false,
  API_URL_BASE: "https://stage-stammdaten.em-sourcing.com/",
  API_URL_SUG: "https://stage-stammdaten.sommer-gossmann.com/",
  API_ROOT: "api/",
  API_VERSION: '',
  API_CHANGELOG: '[1.42.11] - 2025-01-20',
  SMARTTOMM_URL_BASE: "https://smarttomm.em-sourcing.com/",
  SMARTTOMM_URL_SUG: "https://smarttomm.sommer-gossmann.com/",
  SMARTTOMM_URL_ERROR: "failed/Stammdaten?status=",
  LOGIN_URL_BASE: "https://login.em-sourcing.com/",
  LOGIN_URL_SUG: "https://login.sommer-gossmann.com/",
  SSO_REALM_BASE: "smarttomm",
  SSO_REALM_SUG: "sug",
  SSO_CLIENT_ID_BASE: "stammdaten",
  SSO_CLIENT_ID_SUG: "stammdatenmanagement",
  sentry: {
    dsn: 'https://f5bf70da1c4b2771d568cf452d7f1a9a@sentry.sommer-gossmann.com/3',
    release: '7243b646'
  }
};

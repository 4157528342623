import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../environments/environment';
import {
  faAddressCard,
  faEuroSign,
  faNewspaper,
  faPeopleRoof,
  faTruckFast,
  faUsers
} from '@fortawesome/free-solid-svg-icons';
import { IconDefinition } from '@fortawesome/free-regular-svg-icons';

function chunkArray(items: any[], chunkSize: number) {
  const numberOfChunks = Math.ceil(items.length / chunkSize)

  return [...Array(numberOfChunks)].map((_, index) => items.slice(index * chunkSize, (index + 1) * chunkSize));
}

interface Role {
  name: string[];
  module: string[];
  access: string[];
  group?: string;
}

interface MenuItem {
  name: string;
  route: string;
  icon: IconDefinition;
}

interface Client {
  ident: string;
  name: string;
}

@Injectable({
  providedIn:  'root'
})
export class RolesService {
  chunkSize = 3

  private matrix: MenuItem[][] = []
  private menuUser: MenuItem[] = [];

  tokenParsed: any | undefined = this.keycloak.getKeycloakInstance().tokenParsed;

  menuStructure: MenuItem[] = [
    { name: 'Filialen', route: '/filialen', icon: faPeopleRoof },
    { name: 'Ansprechpartner', route: '/ansprechpartner', icon: faUsers },
    { name: 'Firmen', route: '/firmen', icon: faAddressCard },
    { name: 'Produkte', route: '/produkte', icon: faTruckFast },
    { name: 'Medien', route: '/medien', icon: faNewspaper },
    { name: 'Konditionsverwaltung', route: '/konditionsverwaltung', icon: faEuroSign }
  ];

  availableRoles: Role[] = [
    {
      name: ['rolle_ems_admin', 'rolle_ems_admin_global', 'rolle_sug_admin', 'rolle_sug_admin_global'],
      module: ['Filialen', 'Ansprechpartner', 'Firmen', 'Produkte', 'Medien', 'Konditionsverwaltung'],
      access: ['grant'],
      group: 'admin'
    },
    {
      name: ['rolle_sug_mehrere_kunden'],
      module: ['Filialen', 'Ansprechpartner', 'Firmen', 'Produkte', 'Medien'],
      access: ['sug'],
      group: 'companies'
    },
    {
      name: ['rolle_ems_ein_kunde', 'rolle_ems_mehrere_kunden'],
      module: ['Filialen', 'Ansprechpartner', 'Firmen', 'Produkte', 'Medien'],
      access: ['ems'],
      group: 'companies'
    },
    {
      name: ['rolle_rtg_admin', 'rolle_kunde_admin'],
      module: ['Filialen', 'Ansprechpartner'],
      access: ['customer'],
      group: 'companies'
    },
    {
      name: ['rolle_rtg_eine_filiale', 'rolle_rtg_mehrere_filialen', 'rolle_kunde_eine_filiale', 'rolle_kunde_mehrere_filialen'],
      module: ['Filialen', 'Ansprechpartner'],
      access: ['customer'],
      group: 'branches'
    },
    {
      name: [],
      module: [],
      access: ['denied']
    }
  ];

  availableClients: Client[] = [
    { ident: 'em-sourcing', name: 'ems'},
    { ident: 'sommer-gossmann', name: 'sug'}
  ];

  constructor(
    private keycloak: KeycloakService,
    private cookieService: CookieService
  ) {}

  public getUserAccess() {
    const currentRole = this.findTokenRoles();
    if (!currentRole) {
      return ;
    }
    return currentRole.access;
  }

  public getUserRolesGroup() {
    const currentRole = this.findTokenRoles();
    return currentRole?.group;
  }

  public getCurrentClient(): string | null | undefined {
    const client = this.getTokenIss().split('/')[2].split('.')[1];
    return client ? this.availableClients.find((key: any) => key.ident === client)?.name : null;
  }

  public getTokenRealmAccess() {
    return this.tokenParsed.realm_access;
  }

  public getTokenIss() {
    return this.tokenParsed.iss;
  }

  public getTokenBranchIds() {
    return this.tokenParsed.branch_number_customer;
  }

  public getTokenCompanyIds() {
    return this.tokenParsed.company_id;
  }

  public getTokenSectionIds() {
    return this.tokenParsed.section_id;
  }

  public getTokenRoles() {
    return this.tokenParsed.realm_access.roles;
  }

  public getCurrentUserName() {
    return this.tokenParsed.name || '';
  }

  public setAllUserRolesCookies() {
    const currentRole = this.findTokenRoles();

    if (!currentRole) {
      return ;
    }

    this.cookieService.set('access', JSON.stringify(currentRole.access));
    this.cookieService.set('group', currentRole.group || '');
    this.cookieService.set('roles', JSON.stringify(currentRole.name));
    // Firmen
    this.cookieService.delete('companies');
    if (this.checkAccessForCompanyIds()) {
      this.cookieService.set('companies', JSON.stringify(this.getTokenCompanyIds()));
    }
    // Filialen
    this.cookieService.delete('branches');
    if (typeof this.getTokenBranchIds() !== 'undefined' && this.getTokenBranchIds().length > 0) {
      this.cookieService.set('branches', JSON.stringify(this.getTokenBranchIds()));
    }
    // Sparten
    this.cookieService.delete('sections');
    if (typeof this.getTokenSectionIds() !== 'undefined' && this.getTokenSectionIds().length > 0) {
      this.cookieService.set('sections', JSON.stringify(this.getTokenSectionIds()));
    }
  }

  public checkAccessForCompanyIds(): boolean {
    return this.getTokenCompanyIds()?.length > 0;
  }

  findTokenRoles(): Role | undefined {
    const tokenRoles = this.getTokenRoles();
    return this.availableRoles.find((role: any) => {
      return role.name.some((name: string) => tokenRoles.includes(name));
    });
  }

  setForwardToErrorUrl(str: string) {
    if(this.cookieService.check('access')) {
      this.cookieService.deleteAll();
    }
    const host = this.getCurrentClient() === 'sug' ? environment.SMARTTOMM_URL_SUG : environment.SMARTTOMM_URL_BASE;
    window.location.href = host + environment.SMARTTOMM_URL_ERROR + encodeURI(str);
  }

  public setMenuUser() {
    const currentRole = this.findTokenRoles();

    if (!currentRole) {
      return;
    }

    if(!currentRole.access || currentRole.access?.[0] === 'denied') {
      this.setForwardToErrorUrl('Du hast keinen Zugriff auf das Stammdaten-Modul!');
    }

    this.menuUser = currentRole.module.map((el: string) => this.menuStructure.find((m: MenuItem) => m.name === el)!);
    this.matrix = chunkArray(this.menuUser, 3);
  }

  public getMenuUser() {
    return this.menuUser;
  }

  public getMenuUserMatrix() {
    return this.matrix;
  }

  hasRoleAdminGlobal() {
    return this.getTokenRoles().some((role: string) => role.endsWith('admin_global'));
  }

  hasRoleCustomer() {
    return this.getTokenRoles().some((role: string) => role.startsWith('rolle_kunde'));
  }

  hasRoleRtgOrCustomer() {
    return this.getTokenRoles().some((role: string) => role.startsWith('rolle_rtg') || role.startsWith('rolle_kunde'));
  }
}
